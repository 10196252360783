<template>
  <v-container>
    <h1> <textloader-component textKey="MottagningenRubrik"/> </h1>
    <paragraph-component> <textloader-component textKey="MottagningenBrödtext"/> </paragraph-component>
    <!-- Bild-karusellen -->
    <v-carousel cycle height="83vh" :show-arrows="false" hide-delimiters>
      <v-carousel-item v-for="(url, i) in urls" :key="i" :src="url" />
    </v-carousel>

  </v-container>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'
export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  },
  data: () => {
    return {
      // Lista med bild-länkar
      urls: [
        require('@/assets/NyStudent/OhmMottagningen/Bild1.jpg'),
        require('@/assets/NyStudent/OhmMottagningen/Bild2.jpg'),
        require('@/assets/NyStudent/OhmMottagningen/Bild3.jpg')
      ]
    }
  }
}
</script>
